import ReactGA from 'react-ga';
import { useOrderStore } from 'data/orders';
import { CustomerInfoType, ShopResponse } from 'data/types';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect } from 'react';
import { initPixel, fbPageView, fbRevokeConsent } from 'utils/fbpixel';
import { initBDTracking, logPageView, revokeGA } from 'utils/tracking';
import { ContextProps, GlobalContext } from 'context/global-context';

export type TrackingProps = {
  shop: ShopResponse;
};

export const Tracking: FC<TrackingProps> = ({ shop }) => {
  const router = useRouter();
  const setRefs = useOrderStore((state) => state.setRefs);
  const order = useOrderStore((state) => state.order);

  const { fbPixelKey, gaKey } = shop.tracking || {};
  const { setPopupState, popupState, setTrackingState } =
    useContext<ContextProps>(GlobalContext);

  // Refs and affiliates
  useEffect(() => {
    // Tilføjes flere her, skal de tilføjes i TARS også (InitRefs)
    const refQueryKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'hltid',
      // 'gclid',
      'ref',
      'affid',
      'campaign',
      'fbclid',
    ];
    const data = refQueryKeys.reduce((prev, key) => {
      if (router.query[key]) {
        return {
          ...prev,
          [key]: router.query[key],
        };
      }
      return prev;
    }, {});
    if (Object.keys(data).length) {
      setRefs(data, { locale: router.locale, path: router.asPath });
    }
  }, [router.query]); // eslint-disable-line

  // Init all trackings
  useEffect(() => {
    if (order) {
      // Beatdown Pixel
      if (order.cookieConsent?.analytics) {
        initBDTracking();
      }

      // FB Pixel
      if (fbPixelKey && order.cookieConsent?.advertising) {
        initPixel(fbPixelKey, router.locale, order as CustomerInfoType);
        // revokeConsent();
      }

      // Google Analytics
      if (gaKey && order.cookieConsent?.analytics) {
        ReactGA.initialize(gaKey, { debug: false });
        ReactGA.plugin.require('ecommerce');
      }

      if (fbPixelKey && order.cookieConsent?.advertising) {
        fbPageView();
      }
      setTrackingState('ready');
    }
    if (order?.cookieConsent) {
      if (!order.cookieConsent.analytics) {
        revokeGA();
      }
      if (!order.cookieConsent.advertising) {
        fbRevokeConsent();
      }
    }
  }, [order?._id, order?.cookieConsent]); // eslint-disable-line

  // PageView() on routeChange
  useEffect(() => {
    const handleRouteChange = (): void => {
      if (popupState !== 'none') {
        setPopupState('none');
      }
      logPageView();
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  return null;
};

export default Tracking;
