import create, { State } from 'zustand';
import { fetchAPI } from './fetch-api-data';
import {
  ConsentSettings,
  MutationResponse,
  Order,
  ShippingRate,
} from './types';
import { getToken } from 'utils/helpers';
import { captureException } from 'utils/sentry';

const mutationResponseQuery = (orderQuery?: string): string => `
  {
    success
    statusCode  
    message
    ${orderQuery ? `order ${orderQuery}` : ''}
    data
  }
`;

const basketQuery = `
  basket {
    items {
      _id
      url
      productId
      exactSku
      amount
      image
      name
      subText
      artist {
        name
      }
      preorder
      finitePreorder
      shippingStartDate
      priceFormatted
      totalPriceFormatted
      errorMessage
      selectedVariants {
        variantName
        attributeName
      }
    }
    currency
    totalItems
    vatFormatted
    totalPriceFormatted
    shippingPrice
    shippingPriceFormatted
    totalPriceIncludeShipping
    totalPriceIncludeShippingFormatted
    noticeMessage
    voucher {
      _id
      code
      discount
      discountPrice
      discountPriceFormatted
    }
  }
`;
// Log every time state is changed
// const log = (config) => (set, get, api) =>
//   config(
//     (args) => {
//       set(args);
//       console.log('-> New Order State', get());
//     },
//     get,
//     api
//   );

type MutationContext = {
  locale: string;
  path?: string;
};

type InitRefs = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  hltid?: string;
  // gclid?: string;
  ref?: string;
  affid?: string;
  campaign?: string;
  fbclid?: string;
};

interface OrderState extends State {
  fetched: boolean;
  order?: Order;
  initOrder: (ctx: MutationContext) => Promise<void>;
  setRefs: (refs: InitRefs, ctx: MutationContext) => Promise<void>;
  addToBasket: (
    props: {
      productId: string;
      amount: number;
      selected: Record<string, string>;
    },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  changeQuantityOnLine: (
    props: { lineId: string; amount: number },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  setCustomerInfo: (
    props: Record<string, unknown>,
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  setShipping: (
    props: { shippingId: number; droppointId?: string; orderId?: string },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  setVoucher: (
    props: { code: string },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  setCookieConsent: (
    props: { settings: ConsentSettings },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  changeCurrency: (
    props: { currency: string },
    ctx: MutationContext
  ) => Promise<MutationResponse>;
  createOrderAndGoToQueue: (ctx: MutationContext) => Promise<MutationResponse>;
}

export const useOrderStore = create<OrderState>((set, get) => ({
  fetched: false,
  order: null,
  initOrder: async ({ locale, path }) => {
    const order = await getCheckoutData({ locale, path }); // eslint-disable-line

    set({ fetched: true, order });
  },
  setRefs: async (refs, { locale, path }) => {
    const res = await setRefs(refs, { locale, path }); // eslint-disable-line
    if (!res.success) {
      captureException('setRefs failed', { res, locale, path, refs });
    }
  },
  addToBasket: async ({ productId, amount, selected }, { locale, path }) => {
    const { order } = get();
    const res = await addToBasket(
      { productId, amount, selected },
      { locale, path }
    );
    if (res.success) {
      set({ order: { ...order, ...res.order } });
    }
    return res;
  },
  changeQuantityOnLine: async ({ lineId, amount }, { locale, path }) => {
    const { order } = get();
    const res = await changeQuantityOnLine(
      { lineId, amount },
      { locale, path }
    ); // eslint-disable-line

    if (res.success) {
      set({ order: { ...order, ...res.order } });
    }
    return res;
  },
  setCustomerInfo: async ({ ...info }, { locale, path }) => {
    const { order } = get();
    // Optimitic UI
    set({ order: { ...order, ...info } });
    const res = await setCustomerInfo({ ...info }, { locale, path }); // eslint-disable-line

    if (res.success) {
      set({ order: { ...order, ...res.order } });
    } else {
      captureException('setCustomerInfo failed', {
        res,
        locale,
        path,
        info,
      });
    }
    return res;
  },
  setShipping: async (
    { shippingId, droppointId = null, orderId = null },
    { locale, path }
  ) => {
    const { order } = get();
    const res = await setShipping(
      { shippingId, droppointId, orderId },
      { locale, path }
    ); // eslint-disable-line

    if (res.success) {
      set({ order: { ...order, ...res.order } });
    } else {
      captureException('setShipping failed', {
        res,
        locale,
        path,
        shippingId,
        droppointId,
      });
    }
    return res;
  },
  setVoucher: async ({ code }, { locale, path }) => {
    if (!code) {
      return null;
    }
    const { order } = get();
    const res = await setVoucher({ code }, { locale, path }); // eslint-disable-line

    if (res.success) {
      set({ order: { ...order, ...res.order } });
    } else {
      captureException('setVoucher failed', {
        res,
        locale,
        path,
        code,
      });
    }
    return res;
  },
  setCookieConsent: async ({ settings }, { locale, path }) => {
    const { order } = get();
    const res = await setCookieConsent({ settings }, { locale, path }); // eslint-disable-line
    console.log('log', res);
    if (res.success) {
      set({ order: { ...order, ...res.order } });
    } else {
      captureException('changeCurrency failed', {
        res,
        locale,
        path,
        settings,
      });
    }
    return res;
  },
  changeCurrency: async ({ currency }, { locale, path }) => {
    const { order } = get();
    const res = await changeCurrency({ currency }, { locale, path }); // eslint-disable-line

    if (res.success) {
      set({ order: { ...order, ...res.order } });
    } else {
      captureException('changeCurrency failed', {
        res,
        locale,
        path,
        currency,
      });
    }
    return res;
  },
  createOrderAndGoToQueue: async ({ locale, path }) => {
    const res = await insertCustomerIntoPaymentQueue({ locale, path }); // eslint-disable-line

    return res;
  },
}));

async function getCheckoutData({ locale, path }): Promise<Order> {
  if (!process.browser) {
    throw new Error('getCheckoutData called from server');
  }
  const query = `
    query {
      order {
        _id
        token
        cookieConsent {
          id
          date
          version
          analytics
          advertising
        }
        email 
        firstName
        lastName
        address
        address2
        zipcode
        city
        country
        countryCode
        phone
        conditions
        newsletter
        message
        ${basketQuery}
        currencyRate
        payment {
          id
          link
        }
        shipping {
          id
          orderId
          droppoint {  
            id
          }
        }
      } 
    }
  `;
  const orderData = await fetchAPI(query, {
    variables: {},
    headers: { locale, path, token: getToken() },
    // debug: true,
  });
  const { order } = orderData || {};
  return order;
}

export async function getShippingMethods({
  locale,
  path,
}: {
  locale: string;
  path: string;
}): Promise<ShippingRate[]> {
  if (!process.browser) {
    throw new Error('getShippingMethods called from server');
  }
  const query = `
    query {
      shippingMethods {
        id
        name
        carrierId
        carrierName
        carrierLogo
        currency
        price
        priceFormatted
        orderId
        description
        requireDropPoint
        droppoints {
          id
          name
          longitude
          latitude
          address
          zipcode
          city
          country
        }
      }
    }
  `;
  const orderData = await fetchAPI(query, {
    headers: { locale, path, token: getToken() },
  });
  const { shippingMethods } = orderData || {};
  return shippingMethods;
}

export async function getCountries({
  locale,
  path,
}: MutationContext): Promise<string[]> {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }
  const query = `
    query { 
      countries
    }
  `;
  const queryData = await fetchAPI(query, {
    headers: { locale, path, token: getToken() },
  });
  return queryData.countries;
}

export async function getCityFromZipcode(
  zipcode: string,
  { locale, path }: MutationContext
): Promise<string> {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }
  const query = `
    query($zipcode: String!) { 
      cityFromZipcode(zipcode: $zipcode)
    }
  `;
  const queryData = await fetchAPI(query, {
    variables: { zipcode },
    headers: { locale, path, token: getToken() },
  });
  return queryData.cityFromZipcode;
}

async function addToBasket(
  { productId, amount, selected },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }
  const mutation = `
    mutation($productId: String!, $amount: Int! $selected: JSON) { 
      addItemToBasket(productId: $productId, amount: $amount, selected: $selected) ${mutationResponseQuery(`{
        _id
        token
        currencyRate
        ${basketQuery}
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { productId, amount, selected },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.addItemToBasket;
}

async function changeQuantityOnLine(
  { lineId, amount },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('changeQuantityOnLine called from server');
  }
  const mutation = `
    mutation($lineId: String!, $amount: Int!) { 
      changeQuantityOnLine(lineId: $lineId, amount: $amount) ${mutationResponseQuery(`{
        _id
        token
        currencyRate
        ${basketQuery}
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { lineId, amount },
    headers: { locale, path, token: getToken() },
  });
  const data = mutationData.changeQuantityOnLine;
  return data;
}

async function setRefs(refs, { locale, path }): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('setCustomerInfo called from server');
  }
  const mutation = `
  mutation($refs: InitRefs!) { 
      setRefs(refs: $refs) ${mutationResponseQuery()} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { refs },
    headers: { locale, path, token: getToken() },
  });

  return mutationData.setRefs;
}

async function setCustomerInfo(
  { validate = false, ...info },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('setCustomerInfo called from server');
  }
  const mutation = `
  mutation($customerInfo: CustomerInfoType!, $validate: Boolean) { 
      setCustomerInfo(customerInfo: $customerInfo, validate: $validate) ${mutationResponseQuery(`{
        _id
        token
        email
        firstName
        lastName
        message
        address
        address2
        zipcode
        city
        country
        countryCode
        phone
        conditions
        newsletter
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { customerInfo: { ...info }, validate },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.setCustomerInfo;
}

async function setShipping(
  { shippingId, droppointId = null, orderId = null },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('setShipping called from server');
  }

  const mutation = `
    mutation($shippingId: Int!, $droppointId: String, $orderId: String) { 
      setShipping(shippingId: $shippingId, droppointId: $droppointId, orderId: $orderId) ${mutationResponseQuery(`{
        _id
        token
        currencyRate
        ${basketQuery}
        shipping {
          id
          orderId
          droppoint {  
            id
          }
        }
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { shippingId, droppointId, orderId },
    headers: { path, locale, token: getToken() },
  });
  return mutationData.setShipping;
}
async function setVoucher(
  { code },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('setVoucher called from server');
  }
  // if (!code) {
  //   return;
  // }
  const mutation = `
    mutation($code: String!) { 
      setVoucher(code: $code) ${mutationResponseQuery(`{
        _id
        token
        currencyRate
        ${basketQuery}
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { code },
    headers: { locale, path, token: getToken() },
  });

  return mutationData.setVoucher;
}
async function changeCurrency(
  { currency },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('changeCurrency called from server');
  }
  if (!currency) {
    return;
  }
  const mutation = `
    mutation($currency: String!) { 
      setCurrency(currency: $currency) ${mutationResponseQuery(`{
        _id
        token
        currencyRate
        ${basketQuery}
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { currency },
    headers: { path, locale, token: getToken() },
  });
  return mutationData.setCurrency;
}

async function setCookieConsent(
  { settings },
  { locale, path }
): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('setCookieConsent called from server');
  }
  if (!settings) {
    return;
  }
  const mutation = `
    mutation($analytics: Boolean!, $advertising: Boolean!) { 
      setCookieConsent(analytics: $analytics, advertising: $advertising) ${mutationResponseQuery(`{
        _id
        token
        cookieConsent {
          id
          date
          version
          analytics
          advertising
        }
      }`)} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: {
      analytics: settings.analytics || false,
      advertising: settings.advertising || false,
    },
    headers: { path, locale, token: getToken() },
  });
  return mutationData.setCookieConsent;
}

async function insertCustomerIntoPaymentQueue({
  locale,
  path,
}): Promise<MutationResponse> {
  if (!process.browser) {
    throw new Error('insertCustomerIntoPaymentQueue called from server');
  }
  const mutation = `
    mutation { 
      insertCustomerIntoPaymentQueue ${mutationResponseQuery()} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: {},
    headers: { locale, path, token: getToken() },
  });
  return mutationData.insertCustomerIntoPaymentQueue;
}

export const getPaymentLinkWhenReady = async (
  { orderId }: { orderId: string },
  { locale, path }: MutationContext
): Promise<MutationResponse> => {
  if (!process.browser) {
    throw new Error('insertCustomerIntoPaymentQueue called from server');
  }
  const mutation = `
    mutation($orderId: String!) { 
      getPaymentLinkWhenReady(orderId: $orderId) ${mutationResponseQuery()} 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: {
      orderId,
    },
    headers: { locale, path, token: getToken() },
  });
  console.log('/// getPaymentLinkWhenReady res', mutationData);
  return mutationData.getPaymentLinkWhenReady;
};

export const getReceipt = async (
  { orderId, token }: { orderId: string; token: string },
  { locale, path }: MutationContext
): Promise<Order> => {
  const query = `
    query($token: String!, $orderId: String!) {
      receipt(token: $token, orderId: $orderId) {
        _id
        token
        orderId
        locale
        status
        statusLabel
        customer {
          email
          firstName
          lastName
          address
          address2
          zipcode
          city
          country
          phone
          conditions
          newsletter
          message
        }
        basket {
          items {
            _id
            url
            productId
            exactSku
            amount
            image  
            name
            subText
            artist {
              name
            }
            preorder
            finitePreorder
            shippingStartDate
            price
            priceFormatted
            totalPrice
            totalPriceFormatted
            selectedVariants {
              variantName
              attributeName
            }
          }
          currency
          totalItems
          vat
          vatFormatted
          totalPrice
          totalPriceFormatted
          shippingPrice
          shippingPriceFormatted
          totalPriceIncludeShipping
          totalPriceIncludeShippingFormatted
          voucher {
            _id
            code
            discount
            discountPrice
            discountPriceFormatted
          }
        }
      }
    }

  `;
  const queryData = await fetchAPI(query, {
    variables: {
      orderId,
      token,
    },
    headers: { locale, path },
  });
  return queryData.receipt;
};
