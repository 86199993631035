import { fetchAPI } from './fetch-api-data';
import {
  SimplePage,
  ShopResponse,
  Theme,
  Tracking,
  Image,
  Currency,
} from './types';
import cache from 'memory-cache';
import { getToken } from 'utils/helpers';

export const getShopSettings = async (
  path: string,
  locale: string,
  shopSlug: string
): Promise<{
  _id: string;
  slug: string;
  devMode: boolean;
  hasVouchers: boolean;
  theme: Theme;
  tracking: Tracking;
  customScripts?: string;
  customStyles?: string;
  logo?: Image;
  favicon?: Image;
  currencies: Currency[];
  messageBar?: string;
}> => {
  const query = `
    query {
      shop {
        _id
        slug
        devMode
        hasVouchers
        currencies {
          key
          name
        }
        dictionary
        theme {
          themeName
          colors {  
            baseBackground
            baseForeground
            baseDimmedForeground
            headerBackground
            headerForeground
            headerDropdownBackground
            headerDropdownForeground
            footerBackground
            footerForeground
            inputBackground
            inputBorder
            inputForeground
            inputPlaceholder
            primaryBackground
            primaryDarkerBackground
            primaryForeground
            secondaryBackground
            secondaryForeground
            buttonBackground
            buttonDisabled
            buttonForeground
            border
            grey
            errorForeground
            lightBackground
            ultraLightBackground
            headerButtonsForeground
            headerButtonsBackground
            cookieConsentBackground
            cookieConsentForeground
            popupBackground
            popupForeground            
          }
          settings {
            fontImport
            fontFamilyBase
            fontFamilyDisplay
            navigationHeight
            navigationHeightCollapsed
            disableCrisp
            disableSearch            
          }
        }
        
        tracking {
          googleVerificationCode
          microsoftClarifyCode
          facebookVerificationCode
          gaKey
          fbPixelKey
        }
        customScripts
        customStyles
        logo 
        favicon 
        messageBar
      }
    }
  `;
  const { shop } =
    (await fetchAPI(query, {
      variables: {},
      headers: { path, locale, shop: shopSlug },
    })) || {};

  return shop || {};
};

export const getInitialShop = async (
  path: string,
  locale: string,
  shopSlug?: string
): Promise<{ shop: ShopResponse; pages: SimplePage[] }> => {
  const cacheKey = `getInitialShop-${locale}-${shopSlug || 'default'}`;

  if (cache.get(cacheKey)) {
    return cache.get(cacheKey);
  }

  const query = `
    query {
      shop {
        _id
        slug
        name
        domain
        devMode
        hasVouchers
        buildPages
        theme {
          themeName
          colors {  
            baseBackground
            baseForeground
            baseDimmedForeground
            headerBackground
            headerForeground
            headerDropdownBackground
            headerDropdownForeground
            footerBackground
            footerForeground
            inputBackground
            inputBorder
            inputForeground
            inputPlaceholder
            primaryBackground
            primaryDarkerBackground
            primaryForeground
            secondaryBackground
            secondaryForeground
            buttonBackground
            buttonDisabled
            buttonForeground
            border
            grey
            errorForeground
            lightBackground
            ultraLightBackground
            headerButtonsForeground
            headerButtonsBackground
            cookieConsentBackground
            cookieConsentForeground
            popupBackground
            popupForeground
          }
          settings {
            fontImport
            fontFamilyBase
            fontFamilyDisplay
            navigationHeight
            navigationHeightCollapsed
            disableCrisp
            disableSearch
          }
        }
        dictionary
        socials {
          facebook
          soundcloud
          instagram
          twitter
          itunes
          spotify
          youtube
          rss
          twitch
          tier
        }
        tracking {
          googleVerificationCode
          microsoftClarifyCode
          facebookVerificationCode
          gaKey
          fbPixelKey
        }

        defaultLanguage
        defaultCurrency
        defaultTitle
        currencies {
          key
          name
        }
        customScripts
        customStyles
        logo 
        favicon 
        messageBar
        footerLinks {
          label
          url
        }
      }
      pages {
        _id
        slug
        name
        type
        hideInNavigation
        href
        children {
          _id
          slug
          type
          name
          hideInNavigation
          href
        }
      }
    }
  `;

  const shop = await fetchAPI(query, {
    variables: {},
    headers: { path, locale, ...(shopSlug ? { shop: shopSlug } : {}) },
  });

  cache.put(cacheKey, shop, process.env.CI ? 10 * 60 * 1000 : 5000);

  return shop;
};

export const newsletterSignup = async ({
  email,
  path,
  locale,
  mode,
  productId,
  tags,
}: {
  email: string;
  path: string;
  locale: string;
  mode: string;
  productId?: string;
  tags?: string[];
}): Promise<boolean> => {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }

  const mutation = `
    mutation($email: String, $mode: String, $productId: String, $tags: [String]) { 
      newsletterSignup(email: $email, mode: $mode, productId: $productId, tags: $tags) 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { email, mode, productId, tags },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.newsletterSignup;
};
