/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useErrorBoundary } from 'use-error-boundary';
import { useRouter } from 'next/router';
import { SimplePage, ShopResponse } from 'data/types';
import SidebarNavigation from 'components/core/sidebar-navigation';
import { GlobalStyle } from 'styles/styles';
import { buildImgixUrl } from 'utils/helpers';
import {
  GlobalContext,
  PopupStates,
  TrackingStates,
} from '../context/global-context';
import { useWindowSize } from '../utils/hooks';
import { captureException } from '../utils/sentry';
import { useOrderStore } from '../data/orders';
import { getShopSettings } from 'data/shop';
import Tracking from 'components/core/tracking';
import Crisp from 'components/core/crisp';

const SidebarCheckout = dynamic(
  () => import('../components/checkout/sidebar-checkout'),
  { ssr: false }
);

type AppWrapperProps = {
  shop: ShopResponse;
  pages: SimplePage[];
};

export const AppWrapper: React.FC<AppWrapperProps> = ({
  children,
  shop: shopResponse,
  pages = [],
}) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const [trackingState, setTrackingState] = useState<TrackingStates>('none');
  const [popupState, setPopupState] = useState<PopupStates>('none');
  const [currentCurrency, setCurrentCurrency] = useState<string>(
    shopResponse.defaultCurrency
  );
  const [currentCurrencyRate, setCurrentCurrencyRate] = useState<number>(1);
  const [shopData, setShopData] = useState<ShopResponse>(shopResponse);

  const { device } = useWindowSize();
  const initOrder = useOrderStore((state) => state.initOrder);

  const router = useRouter();
  const { locale, asPath, locales, defaultLocale } = router;

  useEffect(() => {
    const fetchShopSettings = async (): Promise<void> => {
      const updatedSettings = await getShopSettings(
        router.asPath,
        router.locale,
        process.env.NEXT_PUBLIC_SHOP_SLUG
      );
      if (updatedSettings?.slug === process.env.NEXT_PUBLIC_SHOP_SLUG) {
        setShopData({ ...shopData, ...updatedSettings });
      }
    };

    // Fetch colors and settings on init
    fetchShopSettings();

    // Fetch order on
    initOrder({ locale, path: asPath });
  }, [locale]); // eslint-disable-line

  const frontpage = ['', '/index', '/'].includes(asPath);

  useEffect(() => {
    if (didCatch) {
      captureException(error);
    }
  }, [didCatch, error]);

  useEffect(() => {
    const bodyClasses = Object.values(router.query);
    bodyClasses.push(process.env.NEXT_PUBLIC_SHOP_SLUG);
    bodyClasses.push(device);
    if (frontpage) {
      bodyClasses.push('frontpage');
    } else if (router.query.productSlug) {
      bodyClasses.push('product');
    } else {
      bodyClasses.push('page');
    }
    document.querySelector('body').className = bodyClasses.join(' ');
  }, [router.asPath, router.query, device, frontpage]);

  const { dictionary, theme, customStyles, customScripts, ...shop } = shopData;

  return (
    <GlobalContext.Provider
      value={{
        dictionary,
        shop,
        theme,
        popupState,
        setPopupState,
        pages,
        device,
        trackingState,
        setTrackingState,
        currentCurrency,
        setCurrentCurrency,
        currentCurrencyRate,
        setCurrentCurrencyRate,
      }}
    >
      {didCatch ? (
        <p>An error has been catched: {error.message}</p>
      ) : (
        <ErrorBoundary>
          <Head>
            <meta
              name="robots"
              content={shop.devMode ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW'}
            />
            <link
              rel="icon"
              type="image/x-icon"
              href={buildImgixUrl(shop.favicon?.src)}
            />
            <link
              rel="shortcut icon"
              type="image/x-icon"
              href={buildImgixUrl(shop.favicon?.src)}
            />
            {shop.tracking?.googleVerificationCode && (
              <meta
                name="google-site-verification"
                content={shop.tracking.googleVerificationCode}
              />
            )}
            {shop.tracking?.facebookVerificationCode && (
              <meta
                name="facebook-domain-verification"
                content={shop.tracking.facebookVerificationCode}
              />
            )}
            <meta property="og:site_name" content={shop.domain} />
            <meta property="og:site" content={`https://${shop.domain}/`} />
            {locales.map((loc) => {
              return (
                <link
                  key={loc}
                  rel="alternate"
                  hrefLang={loc}
                  href={`https://${shop.domain}/${
                    loc !== defaultLocale ? loc : ''
                  }`}
                />
              );
            })}
            <link
              rel="alternate"
              href={`https://${shop.domain}/${
                defaultLocale !== 'en' ? 'en' : ''
              }`}
              hrefLang="x-default"
            />
          </Head>
          <GlobalStyle
            settings={theme.settings}
            colors={theme.colors}
            customStyles={customStyles}
            popupState={popupState}
          />
          <Tracking shop={shopData} />
          <a className="skip-content" href="#MainContent">
            Skip to content
          </a>
          <div className={`app`}>
            {children}
            <SidebarNavigation />
            <SidebarCheckout />
          </div>
          <Crisp shop={shopData} />
          {customScripts && (
            <script
              id="customScript"
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: customScripts }}
              async
            />
          )}
        </ErrorBoundary>
      )}
    </GlobalContext.Provider>
  );
};
