/* eslint-disable no-unused-vars */
import { PageSettings, Colors, Settings } from 'data/types';
import {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
} from 'styled-components';
import { buildImgixUrl } from 'utils/helpers';

export const deviceSizes = {
  smallMobile: 360,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  wide: 1250,
};

type MQ = {
  smallMobile: string;
  mobile: string;
  tablet: string;
  desktop: string;
  wide: string;
};

export const mqFrom: MQ = {
  smallMobile: `@media (min-width: ${deviceSizes.smallMobile}px)`,
  mobile: `@media (min-width: ${deviceSizes.mobile}px)`,
  tablet: `@media (min-width: ${deviceSizes.tablet}px)`,
  desktop: `@media (min-width: ${deviceSizes.desktop}px)`,
  wide: `@media (min-width: ${deviceSizes.wide}px)`,
};

export const mqUntil: MQ = {
  smallMobile: `@media (max-width: ${deviceSizes.smallMobile}px)`,
  mobile: `@media (max-width: ${deviceSizes.mobile}px)`,
  tablet: `@media (max-width: ${deviceSizes.tablet}px)`,
  desktop: `@media (max-width: ${deviceSizes.desktop}px)`,
  wide: `@media (max-width: ${deviceSizes.wide}px)`,
};

export const shadows = {
  right: `box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);`,
  left: `box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);`,
  bottom: `box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);`,
  top: `box-shadow: 0px -5px 15px 0px rgba(0,0,0,0.1);`,
  modal: `box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);`,
};

interface PageStylesProps {
  settings: PageSettings;
}

export const getBoxStyles = (items: number): FlattenSimpleInterpolation => {
  if (items < 4) {
    return css`
      display: grid;
      gap: 1rem 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      ${mqFrom.tablet} {
        gap: 1rem 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        > div {
          flex: 1 1 100%;

          ${mqFrom.tablet} {
            flex: 1 1 300px;
            max-width: 300px;
          }
          ${mqFrom.desktop} {
            flex: 1 1 350px;
            max-width: 350px;
          }
          ${mqFrom.wide} {
            flex: 1 1 400px;
            max-width: 400px;
          }
        }
      }

      &.small {
        gap: 1rem 0.8rem;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        ${mqFrom.tablet} {
          gap: 3rem 0.8rem;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
      }
    `;
  }
  if (items < 5) {
    return css`
      display: grid;
      gap: 1rem 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      ${mqFrom.tablet} {
        gap: 1rem 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        > div {
          flex: 1 1 100%;

          ${mqFrom.tablet} {
            flex: 1 1 300px;
            max-width: 300px;
          }
          ${mqFrom.desktop} {
            flex: 1 1 350px;
            max-width: 350px;
          }
          ${mqFrom.wide} {
            flex: 1 1 400px;
            max-width: 400px;
          }
        }
      }

      &.small {
        gap: 1rem 0.8rem;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        ${mqFrom.tablet} {
          gap: 3rem 0.8rem;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
      }
    `;
  }

  return css`
    display: grid;
    gap: 1rem 0.8rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    ${mqFrom.tablet} {
      gap: 3rem 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    &.small {
      gap: 1rem 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      ${mqFrom.tablet} {
        gap: 3rem 0.8rem;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }
    }
  `;
};

export const PageStyles = createGlobalStyle<PageStylesProps>`
  body {
    ${(props) =>
      props.settings?.splashPage
        ? css`
            padding-top: 0;
          `
        : ''}
    ${(props) =>
      props.settings?.backgroundImageDesktop
        ? css`
            background-image: url(${buildImgixUrl(
              props.settings?.backgroundImageDesktop.src
            )});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
          `
        : ''}

    
    ${mqUntil.tablet} {
      ${(props) =>
        props.settings?.backgroundImageMobile
          ? css`
              background-image: url(${buildImgixUrl(
                props.settings?.backgroundImageMobile.src
              )});
            `
          : ''}
    }
  }

  ${(props) =>
    props.settings?.splashPage
      ? css`
          .crisp-client {
            display: none;
          }
        `
      : ''}
  ${(props) => props.settings?.customStyles || ''};

  ${(props) =>
    props.settings?.splashPage
      ? css`
          :root {
            --navigation-height: 0 !important;
            --side-padding: 2rem !important;
          }
          .app {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .app main {
            max-width: 800px;
            flex: 1 1 0px;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `
      : ''}
`;

interface GlobalStyleProps {
  colors: Colors;
  settings: Settings;
  customStyles?: string;
  popupState?: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${({ settings }) => settings.fontImport || ''}

  :root {
    --base-background: ${({ colors }) => colors?.baseBackground};
    --base-foreground: ${({ colors }) => colors?.baseForeground};
    --base-dimmed-foreground: ${({ colors }) => colors?.baseDimmedForeground};

    --header-background: ${({ colors }) => colors?.headerBackground};
    --header-foreground: ${({ colors }) => colors?.headerForeground};
    --header-dropdown-background: ${({ colors }) =>
      colors?.headerDropdownBackground};
    --header-dropdown-foreground: ${({ colors }) =>
      colors?.headerDropdownForeground};
    --header-buttons-background: ${({ colors }) =>
      colors?.headerButtonsBackground};
    --header-buttons-foreground: ${({ colors }) =>
      colors?.headerButtonsForeground};
    
    --footer-background: ${({ colors }) => colors?.footerBackground}; 
    --footer-foreground: ${({ colors }) => colors?.footerForeground};
   
    --input-background: ${({ colors }) => colors?.inputBackground};
    --input-border: ${({ colors }) => colors?.inputBorder};
    --input-foreground: ${({ colors }) => colors?.inputForeground};
    --input-placeholder: ${({ colors }) => colors?.inputPlaceholder}; 

    --primary-background: ${({ colors }) => colors?.primaryBackground};
    --primary-darker-background: ${({ colors }) =>
      colors?.primaryDarkerBackground};
    --primary-foreground: ${({ colors }) => colors?.primaryForeground};

    --secondary-background: ${({ colors }) => colors?.secondaryBackground};
    --secondary-foreground: ${({ colors }) => colors?.secondaryForeground};
 
    --button-background: ${({ colors }) => colors?.buttonBackground};
    --button-disabled: ${({ colors }) => colors?.buttonDisabled};
    --button-foreground: ${({ colors }) => colors?.buttonForeground};
    --button-border-radius: 50px;

    --border: ${({ colors }) => colors?.border};
    --grey: ${({ colors }) => colors?.grey};
    
    --error-foreground: ${({ colors }) => colors?.errorForeground};

    --font-family-base: ${({ settings }) => settings.fontFamilyBase};
    --font-family-display: ${({ settings }) => settings.fontFamilyDisplay};
    --navigation-height: ${({ settings }) => settings.navigationHeight};;
    --navigation-height-collapsed: ${({ settings }) =>
      settings.navigationHeightCollapsed};

    --light-background: ${({ colors }) => colors?.lightBackground};
    --ultra-light-background: ${({ colors }) => colors?.ultraLightBackground};

    --cookie-consent-background: ${({ colors }) =>
      colors?.cookieConsentBackground};
    --cookie-consent-foreground: ${({ colors }) =>
      colors?.cookieConsentForeground};

    --popup-background: ${({ colors }) => colors?.popupBackground};
    --popup-foreground: ${({ colors }) => colors?.popupForeground};


    --sidebar-background: var(--popup-background);
    --sidebar-hover: var(--ultra-light-background);
    --input-border-disabled: #eaeaea;

    --input-size: 3rem;
    --input-border-radius: 3px;

    --side-padding: 0.8rem;
    ${mqFrom.mobile} {
      --side-padding: 1rem;
    }
    ${mqFrom.tablet} {
      --side-padding: 1.5rem;
    }
    ${mqFrom.desktop} {
      --side-padding: 2.5rem;
    }

 


  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--input-background) inset;
  }

  input:-internal-autofill-selected {
    background-color: red !important;
  }

  :focus {
    outline: none;
    /* outline: none; // var(--primary-background) solid 2px;
    box-shadow: 0 0 0 2px var(--primary-background);
    border-color: var(--primary-background) !important; */
  }

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;



    ${mqUntil.mobile} {
      font-size: 14px
    }
  }

  html,
  body {
    font-family: var(--font-family-base);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--base-background);
    color: var(--base-foreground);
  }

  body {
    position: relative;
    min-height: 100vh;
    margin: 0;
    padding-top: var(--navigation-height);
    line-height: 1.4;
    display: block;

    ${({ popupState }) =>
      popupState && popupState !== 'none' ? `overflow-y: hidden;` : ''}

  }

  button {
    font-family: var(--font-family-display);
  }

  img {
    display: block;
  }

  hr {
    height: 1px;
    background: var(--border);
    border: 0;
    margin: 3rem 0;
  }

  .mobile-only {
    ${mqFrom.desktop} {
      display: none;
    }
  }
  .desktop-only {
    ${mqUntil.desktop} {
      display: none;
    }
  }

#__next {
  display: flex;
  flex: 1 1 0;
}


.app {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  main {
    flex: 1 1 0;
  }
  
}

  .skip-content {
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
    top: 0;
    left: 0;

    &:focus {
      clip: auto;
      width: auto;
      height: auto;
      margin: 0;
      color: var(--secondary-foreground);
      background-color: var(--secondary-background);
      padding: 10px;
      opacity: 1;
      z-index: 10000;
      transition: none;
    }
  }

  /* Storybook stuff */
  #story--core-header--* {
    position: relative;
  }


  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-font-smoothing: inherit;
    border: none;
    background: transparent;
    line-height: normal;
  }
  
  /* main {
    ${({ popupState }) => (popupState !== 'none' ? `filter: blur(4px);` : '')}
  } */



  input, textarea {
    caret-color: var(--primary-background);
    background: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--input-foreground);
    border-radius: 3px;

  }

  .select, .input {
    position: relative;
    background: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--input-foreground);
    border-radius: var(--input-border-radius);
    width: 100%;


    label {
      color: var(--input-placeholder);
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: calc(var(--input-size) * 0.4);
      ${mqUntil.tablet} {
        left: calc(var(--input-size) * 0.4);
      }
      opacity: 0.7;
      padding: calc(var(--input-size) * 0.3) 0;
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform-origin: 0 0;
      z-index: 1;
      pointer-events: none;
      font-size: 1rem;// calc(var(--input-size) * 0.5);
    }

    input,
    textarea,
    select {
      border: none;
      /* background: transparent; */
      position: relative;
      font-size: 1rem; // calc(var(--input-size) * 0.5);

      padding: calc(var(--input-size) * 0.3) calc(var(--input-size) * 0.4);

      /* ${mqUntil.tablet} {
        padding: calc(var(--input-size) * 0.3) calc(var(--input-size) * 0.4);
      }
       */
      width: 100%;
      border-radius: var(--input-border-radius);
      transition: all 0.2s ease-in-out;
      outline: none;
      
    }
    &.is-focused {
      /* box-shadow: 0 0 0 1px var(--primary-background); */
      background: var(--input-background);
      border-color: var(--primary-darker-background)
    }
    &.disabled {
      --input-border: #dadada;
      --input-foreground: #9e9e9e;
      --input-background: #f1f1f1;

    }

    &.has-error {
      .error {
        position: absolute;
        bottom: 0; 
      }

    }

    &.is-focused label,
    &.has-value label {
      transform: scale(0.7) translateY(calc(var(--input-size) * -0.1));
      color: var(--base-dimmed-foreground);
      font-weight: 600;
    }
    &.is-focused,
    &.has-value {
      &.has-placeholder {
        input,
        textarea,
        select {
          padding: calc(var(--input-size) * 0.5) calc(var(--input-size) * 0.4) calc(var(--input-size) * 0.1) calc(var(--input-size) * 0.4);
          /* ${mqUntil.tablet} {
           padding: calc(var(--input-size) * 0.5) calc(var(--input-size) * 0.4) calc(var(--input-size) * 0.1) calc(var(--input-size) * 0.4);
          } */
        }
      }
    }
  }

  .error-message {
    color: var(--error-foreground);
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0.3rem 0;
  }

  .skeleton-loading {


    .skel {
      background: #d9d9d9;
      border-radius: 300px;
      position: relative;
      overflow: hidden;
      color: transparent !important;
      opacity: 1 !important;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
          to right,
          #d9d9d9 0%,
          rgba(0, 0, 0, 0.05) 20%,
          #d9d9d9 40%,
          #d9d9d9 100%
        );
        background-repeat: no-repeat;
        background-size: 450px 400px;
        animation: shimmer 1s linear infinite;
      }

      &.skel-s {
        width: 100px;
      }
      &.skel-m {
        width: 200px;
      }
      &.skel-l {
        width: 300px;
      }
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -450px 0;
    }
    100% {
      background-position: 450px 0;
    }
  }

  h1, h2, h3, h4, h5 {
    font-family: var(--font-family-display);
    font-weight: 600;
  }


  h1, h2, h3, h4, h5,
  .h1, .h2, .h3, .h4, .h5 {
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.5px;

    &.display {
      display: inline-block;
      font-weight: 800;
      text-align: center;
      background: linear-gradient(
        135deg,
        var(--primary-background) 0%, 
        var(--secondary-background) 100%
      );
      color: var(--primary-background);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
      /* text-transform: uppercase; */
    }

    &.light {
      font-family: var(--font-family-base);
      font-weight: 500;
    }

  }


  h1,
  .h1 {
    margin: 1rem 0 1rem 0;
    font-size: 1.8rem;

    &.display {
      margin: 3rem 0 1rem 0;
      font-size: 2.2rem;
    }


    ${mqFrom.desktop} {
      font-size: 3rem;
      &.display {
        margin: 6rem 0 2rem 0;
        font-size: 3.8rem;
      }
    }
  }

  h2, .h2 {
    font-size: 1.3rem;
    margin: 1.4rem 0;

    &.display {
      margin: 3rem 0 1rem 0;
      font-size: 1.8rem;
    }

    ${mqFrom.desktop} {
      font-size: 1.8rem;
      &.display {
        margin: 6rem 0 2rem 0;
        font-size: 3rem;
      }
    }
  }
  
  h3, .h3 {
    font-size: 1.2rem;
    margin: 2rem 0 1.2rem 0;

    &.display {
      margin: 4rem 0 2rem 0;
      font-size: 1.6rem;
    }


    ${mqFrom.desktop} {
      font-size: 1.6rem;
      &.display {
        font-size: 2rem;
      }
    }

  }

  h4, .h4 {
    font-size: 1.1rem;
    margin: 1rem 0;
    font-weight: 500;
  }


  p, .body, .body2 {
    font-weight: normal;
    font-size: 1rem;
  }

  p {
    margin: 0.5rem 0;
  }

  .body2 {
    color: var(--base-dimmed-foreground);
  }

  .body3 {
    color: var(--secondary-background);
  }

  small, .small {
    font-weight: 600;
    font-size: 0.8rem;
  }

  a {
    color: var(--secondary-background);
  }


  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle,
      rgba(236, 236, 236, 0.2) 0%,
      rgba(215, 215, 215, 0.2) 50%
    );
    mix-blend-mode: multiply;
  }

  // Storybook stuff
  .sb-show-main {
    background: var(--light-background);

    .form-item-wrapper {
      max-width: 600px;
      padding: var(--side-padding);
      background:  var(--base-background);

      h3 {
        margin-top: 5rem
      }
    }
  }

  // Crisp
  body .crisp-client .cc-kv6t  {
    z-index: 10 !important;
  } 

  body .crisp-client .cc-kv6t .cc-1ada, 
  body .crisp-client .cc-kv6t .cc-ew5j:before, 
  body .crisp-client .cc-kv6t .cc-ew5j:after {
    background-color: var(--secondary-background) !important;
  }

  ${(props) => props.customStyles || ''}

`;
