import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import { IS_PRODUCTION } from './constants';
import { getToken } from './helpers';
import { logBDEvent } from './tracking';

export const initSentry = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];
    if (
      process.env.NEXT_IS_SERVER === 'true' &&
      process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///'
            );
            frame.filename = frame.filename.replace('.next', '_next');
            return frame;
          },
        })
      );
    }

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    });
  }
};

export const addBreadcrumb = (
  message: string,
  category: 'default' | 'debug' | 'error' | 'navigation'
): void => {
  Sentry.addBreadcrumb({
    category: category || 'default',
    message,
    level: Sentry.Severity.Info,
  });
};

export const captureException = (
  _err: any, // eslint-disable-line
  info?: Record<string, unknown>
): void => {
  if (IS_PRODUCTION) {
    const err = typeof _err === 'string' ? new Error(_err) : _err;

    Sentry.configureScope((scope) => {
      if (err.message) {
        // De-duplication currently doesn't work correctly for SSR / browser errors
        // so we force deduplication by error message if it is present
        scope.setFingerprint([err.message]);
      }

      scope.setTag('shop', process.env.NEXT_PUBLIC_SHOP_SLUG);
      if (process.browser) {
        scope.setExtra('token', getToken());
      }
      if (info) {
        Object.keys(info).forEach((key) => scope.setExtra(key, info[key]));
      }
    });

    logBDEvent('error', err.message, info);

    Sentry.captureException(err);
  } else {
    console.log('// captureException', _err);
  }
};
