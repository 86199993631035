import Button from 'components/formitems/button';
import { ContextProps, GlobalContext } from 'context/global-context';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ImFacebook, ImTwitter, ImSoundcloud } from 'react-icons/im';
import {
  FaInstagram,
  FaYoutube,
  FaSpotify,
  FaItunesNote,
  FaTwitch,
  FaRss,
} from 'react-icons/fa';
import { Socials } from 'data/types';

export type SocialIconsProps = {
  override?: Socials;
};

export const SocialIcons: FC<SocialIconsProps> = ({ override }) => {
  const {
    shop: { socials: shopSocials },
  } = useContext<ContextProps>(GlobalContext);

  const socials = override || shopSocials;

  const socialKeys = [
    'facebook',
    'instagram',
    'twitter',
    'youtube',
    'spotify',
    'itunes',
    'soundcloud',
    'twitch',
    'rss',
  ];

  const icons = {
    facebook: ImFacebook,
    twitter: ImTwitter,
    instagram: FaInstagram,
    youtube: FaYoutube,
    spotify: FaSpotify,
    itunes: FaItunesNote,
    soundcloud: ImSoundcloud,
    twitch: FaTwitch,
    rss: FaRss,
  };
  return (
    <StyledSocialIcons className="socials">
      {socialKeys.map((key) => {
        const social = socials[key];
        if (social) {
          return (
            <Button
              key={key}
              icon={icons[key]}
              onClick={() => window.open(social)}
            />
          );
        }
        return null;
      })}
    </StyledSocialIcons>
  );
};

export const StyledSocialIcons = styled.div`
  button {
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
      transform: translateY(-10px);
    }
  }
`;

export default SocialIcons;
