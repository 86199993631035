import styled from 'styled-components';
import { FiX } from 'react-icons/fi';
import { mqFrom, shadows } from '../../styles/styles';
import Button from 'components/formitems/button';

export type ModalProps = {
  visible: boolean;
  children: React.ReactNode;
  close: () => void;
};

type StyledProps = {
  visible: boolean;
};

const Modal: React.FC<ModalProps> = ({ children, visible, close }) => (
  <>
    <StyledBackdrop visible={visible} onClick={close} />
    <StyledModal
      visible={visible}
      aria-hidden={visible ? 'false' : 'true'}
      role={visible ? 'dialog' : 'none'}
    >
      <Button
        className="close-btn"
        onClick={close}
        icon={FiX}
        size="small"
        transparent
      />
      <div className="inner">{children}</div>
    </StyledModal>
  </>
);
const StyledBackdrop = styled.div<StyledProps>`
  position: fixed;
  z-index: 10000;
  top: ${(props) => (props.visible ? 0 : 100)}%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  background: rgba(0, 0, 0, 0.5);
`;
const StyledModal = styled.div<StyledProps>`
  position: fixed;
  top: ${(props) => (props.visible ? 50 : 100)}%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, ${(props) => (props.visible ? -50 : 0)}%);
  background: white;
  color: black;
  padding: 1rem;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  min-width: 300px;
  max-height: 95vh;
  padding: 2rem 1rem 1.5rem 1rem;
  box-shadow: ${shadows.modal};
  z-index: 10001;

  ${mqFrom.desktop} {
    max-width: 450px;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modal-content {
      max-width: 400px;
      padding: 0.5rem 1rem;

      h1,
      h2,
      h3 {
        margin: 0;
      }
    }

    > button {
      padding: 0.5rem;
      color: #333;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 700;
      display: block;
      position: relative;
      line-height: 1rem;

      &:hover,
      &:active,
      &.active {
        background: black;
        color: white;
      }
    }

    .confirm-modal-content {
      max-width: 400px;
      font-size: 1.1rem;
      padding: 1rem 2rem 2rem 2rem;
      text-align: center;
      ${mqFrom.desktop} {
        text-align: left;
      }
    }

    .confirm-modal-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      ${mqFrom.desktop} {
        flex-direction: row;
      }
    }
  }
`;

export default Modal;
