/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import Loader from '../core/load-spinner';
import Modal from 'components/core/modal';
import { FiCheck, FiX } from 'react-icons/fi';
import Link from 'next/link';
import { mqUntil } from 'styles/styles';
import { isExternalLink } from 'utils/helpers';
import { ContextProps, GlobalContext } from 'context/global-context';

type LinkProps = {
  prefetch?: boolean;
  href: string;
  locale?: string;
  target?: string;
};

export type ButtonProps = {
  size?: 'default' | 'large' | 'small';
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onKeyPress?: (
    e: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  icon?: any; // eslint-disable-line
  alignIcon?: 'right' | 'left';
  htmlType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean;
  secondary?: boolean;
  display?: boolean;
  transparent?: boolean;
  noPadding?: boolean;
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  children?: React.ReactNode | string;
  confirmText?: React.ReactNode | string;
  linkProps?: LinkProps;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  size = 'default',
  onClick,
  onKeyPress,
  icon: Icon,
  alignIcon = 'left',
  htmlType = 'button',
  disabled,
  loading,
  className,
  secondary,
  transparent,
  noPadding,
  fullWidth,
  confirmText,
  linkProps,
  display,
  ...props
}) => {
  const iconSizes = {
    default: 20,
    large: 30,
    small: 18,
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  const IconElem = Icon ? (
    <Icon className={`icon icon-${alignIcon}`} size={iconSizes[size]} />
  ) : null;

  const linkAddon: Record<string, string> = {};
  if (isExternalLink(linkProps?.href || '')) {
    linkAddon.href = linkProps.href;
  }
  if (linkProps?.target && linkProps.target) {
    linkAddon.target = linkProps.target;
  }

  return (
    <>
      {confirmText && (
        <Modal
          visible={showConfirmModal}
          close={() => setShowConfirmModal(false)}
        >
          <div
            className="confirm-modal-content"
            dangerouslySetInnerHTML={{ __html: confirmText as string }}
          />
          <div className="confirm-modal-buttons">
            <Button
              size="small"
              icon={FiX}
              onClick={() => setShowConfirmModal(false)}
              transparent
            >
              {dictionary.confirmCancel}
            </Button>
            <Button
              size="small"
              icon={FiCheck}
              onClick={(e) => {
                setShowConfirmModal(false);
                if (onClick) {
                  onClick(e);
                }
              }}
            >
              {dictionary.confirmOkay}
            </Button>
          </div>
        </Modal>
      )}

      {linkProps ? (
        <Link {...linkProps}>
          <StyledAnchor
            className={classNames(
              `btn`,
              className || '',
              `size-${size}`,
              { disabled: !!disabled },
              { transparent: !!transparent },
              { 'no-padding': !!noPadding },
              { display: !!display },
              { 'full-width': !!fullWidth },
              { empty: !children },
              { loading: !!loading },
              { secondary: !!secondary }
            )}
            onKeyPress={(e) => {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onKeyPress) {
                onKeyPress(e);
              }
            }}
            onClick={(e) => {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onClick) {
                onClick(e);
              }
            }}
            {...props}
            {...linkAddon}
          >
            <>
              {alignIcon === 'left' &&
                (loading ? (
                  <Loader
                    className={`icon icon-${alignIcon}`}
                    negative
                    size={iconSizes[size]}
                  />
                ) : (
                  IconElem
                ))}
              <span>{children}</span>
              {alignIcon === 'right' &&
                (loading ? (
                  <Loader
                    className={`icon icon-${alignIcon}`}
                    negative
                    size={iconSizes[size]}
                  />
                ) : (
                  IconElem
                ))}
            </>
          </StyledAnchor>
        </Link>
      ) : (
        <StyledButton
          type={htmlType}
          disabled={disabled || loading}
          className={classNames(
            `btn`,
            className || '',
            `size-${size}`,
            { disabled: !!disabled },
            { transparent: !!transparent },
            { 'no-padding': !!noPadding },
            { 'full-width': !!fullWidth },
            { empty: !children },
            { loading: !!loading },
            { secondary: !!secondary }
          )}
          onClick={(e) => {
            // e.cancelBubble = true;
            e.stopPropagation();
            if (!disabled && !loading) {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onClick) {
                onClick(e);
              }
            }
          }}
          {...props}
        >
          <>
            {alignIcon === 'left' &&
              (loading ? (
                <Loader
                  className={`icon icon-${alignIcon}`}
                  negative
                  size={iconSizes[size]}
                />
              ) : (
                IconElem
              ))}
            <span>{children}</span>
            {alignIcon === 'right' &&
              (loading ? (
                <Loader
                  className={`icon icon-${alignIcon}`}
                  negative
                  size={iconSizes[size]}
                />
              ) : (
                IconElem
              ))}
          </>
        </StyledButton>
      )}
    </>
  );
};

const styleCss = css`
  color: var(--button-foreground);
  background: var(--button-background);
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: var(--button-border-radius);
  white-space: nowrap;
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.full-width {
    width: 100%;
  }

  svg[fill]:not([fill='none']),
  svg:not([fill]) {
    fill: var(--button-foreground);
  }

  svg[stroke]:not([stroke='none']),
  svg:not([stroke]) {
    stroke: var(--button-foreground);
  }

  span {
    transition: all 1.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .icon {
    position: relative;
    .loader {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .icon-right {
    margin-left: 0.4rem;
    margin-right: -0.4rem;
  }
  .icon-left {
    margin-right: 0.4rem;
    margin-left: -0.4rem;
  }

  &.secondary {
    --button-foreground: var(--secondary-foreground);
    --button-background: var(--secondary-background);
  }

  &.transparent {
    --button-foreground: var(--base-foreground);
    --button-background: transparent;

    &.secondary {
      --button-foreground: var(--secondary-background);
    }
  }

  &.disabled {
    --button-background: var(--button-disabled);
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
  }

  &.size-small {
    padding: 5px 14px;
    font-size: 0.85rem;
    font-weight: 600;
  }
  &.size-default {
    padding: 11px 20px;
    font-size: 0.9rem;
    font-weight: bold;
  }

  &.size-large {
    padding: 15px 25px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &.display {
    --button-background: transparent;
    border: 3px solid var(--button-foreground);
    border-radius: 0px;
    padding: 1rem 3rem;
    font-size: 2.4rem;
    font-weight: 600;

    ${mqUntil.tablet} {
      padding: 0.7rem 2.3rem;
      font-size: 1.8rem;
    }
    ${mqUntil.mobile} {
      padding: 0.7rem 1.6rem;
      font-size: 1.4rem;
    }

    &:hover {
      opacity: 1;

      span {
        transform: scale(1.1);
      }
    }

    &:active {
      opacity: 1;
      span {
        opacity: 0.9;
      }
    }
  }

  &.empty {
    .icon-right,
    .icon-left {
      margin-right: 0;
      margin-left: 0;
      transform: scale(1.4);
    }

    &.size-small {
      padding: 5px 5px;
    }
    &.size-default {
      padding: 11px 11px;
    }

    &.size-large {
      padding: 15px 15px;
    }
  }

  + button {
    margin-left: 15px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  .load-wrapper {
    --secondary-background: var(--button-foreground);
  }

  &.no-padding {
    padding: 0;
  }
`;

const StyledButton = styled.button`
  ${styleCss}
`;

const StyledAnchor = styled.a`
  ${styleCss}
`;

export default Button;
