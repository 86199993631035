import currencyFormatter from 'currency-formatter';

export const BASKET_TOKEN_KEY = 'beatdown-token';

export const IS_PRODUCTION = process.env.NODE_ENV !== 'development';

export const IMGIX_HOST = `http${
  IS_PRODUCTION ? 's' : ''
}://beatdown.imgix.net`;

export const IS_BROWSER = typeof window !== 'undefined';

export const LANGUAGES = [
  {
    key: 'da',
    iso: 'da-DK',
    label: 'Dansk',
    defaultCurrency: 'DKK',
  },
  {
    key: 'en',
    iso: 'en-US',
    label: 'English',
    defaultCurrency: 'USD',
  },
];

export const CONTENT_PAGE_SLUGS = ['terms', 'faq', 'privacy-policy'];

// DELT MED TARS
export const CURRENCIES = [
  {
    key: 'DKK',
    defaultLocale: 'da',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: 'DKK',
        decimal: ',',
        thousand: '.',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString.replace(',00', ',-');
    },
  },
  {
    key: 'SEK',
    defaultLocale: 'da',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: 'SEK',
        decimal: ',',
        thousand: ' ',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString.replace(',00', ':-');
    },
  },
  {
    key: 'NOK',
    defaultLocale: 'da',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: 'NOK',
        decimal: ',',
        thousand: ' ',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString.replace(',00', ',-');
    },
  },
  {
    key: 'GBP',
    defaultLocale: 'en',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: '£',
        decimal: '.',
        thousand: ',',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString.replace('.00', '');
    },
  },
  {
    key: 'USD',
    defaultLocale: 'en',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: '$',
        decimal: '.',
        thousand: ',',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString.replace('.00', '');
    },
  },
  {
    key: 'EUR',
    defaultLocale: 'en',
    format: (num: number): string => {
      // const hasDecimals = num % 1 !== 0;
      const convertedString = currencyFormatter.format(num, {
        symbol: '€',
        decimal: '.',
        thousand: ',',
        precision: 2,
        format: '%s %v', // %s is the symbol and %v is the value
      });
      return convertedString;
    },
  },
  {
    key: 'CNY',
    defaultLocale: 'en',
    format: (num: number): string =>
      currencyFormatter.format(num, { code: 'CNY' }),
  },
  {
    key: 'KRW',
    defaultLocale: 'en',
    format: (num: number): string =>
      currencyFormatter.format(num, { code: 'KRW' }),
  },
];

export const RETURN_FORM_LINKS = {
  da: 'https://storage.googleapis.com/beatdown-files/static/returformular.pdf',
  en: 'https://storage.googleapis.com/beatdown-files/static/returformular-us.pdf',
};

export const COUNTRYCODES = {
  DK: 'Danmark',
  FO: 'Færøerne',
  GL: 'Grønland',
  NO: 'Norge',
  DE: 'Germany',
  SE: 'Sverige',
  ES: 'Spain',
  GB: 'Great Britain',
  FI: 'Finland',
  FR: 'France',
  BE: 'Belgium',
  CZ: 'Czech Republic',
  EE: 'Estonia',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  AT: 'Austria',
  SI: 'Slovenia',
  US: 'USA',
  CA: 'Canada',
  BR: 'Brazil',
  AU: 'Australia',
  IN: 'India',
  JP: 'Japan',
  CN: 'China',
  KR: 'South Korea',
  NZ: 'New Zealand',
  RU: 'Russia',
  TH: 'Thailand',
  TR: 'Turkey',
  AE: 'UAE (United Arab Emirates)',
  VN: 'Vietnam',
  HK: 'Hong Kong',
  MX: 'Mexico',
  ID: 'Indonesia',
  SG: 'Singapore',
  TW: 'Taiwan',
  MY: 'Malaysia',
  PH: 'Philippines',
  CH: 'Switzerland',
};
