/* eslint-disable @next/next/no-img-element */
import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { ContextProps, GlobalContext } from 'context/global-context';
import { ListProduct } from 'data/types';
import { getSearchData } from 'data/product';
import { useRouter } from 'next/router';
import { FiSearch } from 'react-icons/fi';
import { buildImgixUrl } from 'utils/helpers';

export type SearchBarProps = {}; // eslint-disable-line

export const SearchBar: FC<SearchBarProps> = () => {
  const { dictionary, theme } = useContext<ContextProps>(GlobalContext);
  const router = useRouter();
  const [suggestions, setSuggestions] = useState<ListProduct[]>([]);
  const [value, setValue] = useState('');
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    setValue('');
    setRedirecting(false);
    setSuggestions([]);
  }, [router.asPath]);

  const renderSuggestion = (suggestion: ListProduct): React.ReactNode => (
    <div className="product-suggestion">
      {suggestion.image?.src && (
        <img
          src={buildImgixUrl(suggestion.image.src, { width: 50, height: 50 })}
          alt={suggestion.name}
        />
      )}
      <div className="info">
        {suggestion.artist?.name && (
          <div className="artist-name">{suggestion.artist?.name}</div>
        )}
        <div className="name">{suggestion.name}</div>
        <div className="price">{suggestion.priceFormatted}</div>
      </div>
    </div>
  );

  const fetchResult = async ({
    value: val,
  }: {
    value: string;
    reason: string;
  }): Promise<void> => {
    const result = await getSearchData(val, router.locale);
    setSuggestions(result ? result.slice(0, 10) : []);
  };

  const inputProps = {
    placeholder: dictionary.searchProducts,
    value,
    onChange: (__, { newValue }) => {
      setValue(newValue);
    },
    onKeyUp: (e) => {
      if (e.keyCode === 13 && e.target.value && !redirecting) {
        router.push(`/search?q=${e.target.value}`);
      }
    },
    'aria-controls': 'search',
  };

  if (theme?.settings?.disableSearch) {
    return null;
  }
  return (
    <StyledSearchBar className="searchbar">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetchResult}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={(val) => val.name}
        onSuggestionSelected={(
          e,
          { suggestion }: { suggestion: ListProduct }
        ) => {
          setRedirecting(true);
          router.push(suggestion.url);
        }}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      <div className="icon">
        <FiSearch size={24} />
      </div>
    </StyledSearchBar>
  );
};

const StyledSearchBar = styled.div`
  position: relative;
  color: var(--base-foreground);
  .react-autosuggest__container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    color: var(--input-foreground);
    align-self: flex-start;
  }

  .react-autosuggest__input {
    background: white;
    width: 100%;
    max-width: 500px;
    padding: 0.6rem 1rem 0.6rem 2.5rem;
    border: 1px solid #aaa;
    border-radius: 2px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
    max-width: 500px;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .product-suggestion {
    display: flex;
    align-items: stretch;
    align-items: flex-start;

    img {
      display: block;
      margin-right: 1rem;
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      .artist-name {
        font-size: 0.8rem;
        margin: 0 0 0.1rem 0;
        font-weight: 600;
      }
      .name {
        font-size: 0.8rem;
        margin: 0 0 0.1rem 0;
      }

      .price {
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
  }
`;
