import { ShopResponse } from 'data/types';
import { FC, useEffect } from 'react';
import { getToken } from 'utils/helpers';

export type CrispProps = {
  shop: ShopResponse;
};

export const Crisp: FC<CrispProps> = ({ shop }) => {
  useEffect(() => {
    if (!shop.theme?.settings?.disableCrisp && process.browser) {
      window.CRISP_WEBSITE_ID = 'cea77922-8b43-4ea3-9681-637b88120312';
      window.CRISP_READY_TRIGGER = () => {
        window.$crisp.push('safe', true);
        window.$crisp.push(['set', 'session:data', ['token', getToken()]]);
      };
      (function () {
        const d = document;
        const s = d.createElement('script');

        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }, [shop.theme?.settings?.disableCrisp]);

  return null;
};

export default Crisp;
