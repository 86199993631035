import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CURRENCIES } from 'utils/constants';
import { ContextProps, GlobalContext } from 'context/global-context';
import { useClickAway } from 'react-use';
import { useRouter } from 'next/router';
import Button from 'components/formitems/button';
import { RiCoinsLine } from 'react-icons/ri';
import Modal from './modal';
import { useOrderStore } from 'data/orders';
// import { useOrderStore } from '../../data/orders';

const CurrencyPicker: React.FC = () => {
  const {
    shop,
    setPopupState,
    currentCurrency,
    setCurrentCurrency,
    currentCurrencyRate,
    setCurrentCurrencyRate,
  } = useContext<ContextProps>(GlobalContext);

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const changeCurrency = useOrderStore((state) => state.changeCurrency);
  const currency = useOrderStore((state) => state?.order?.basket?.currency);
  const currencyRate = useOrderStore((state) => state?.order?.currencyRate);
  const router = useRouter();

  useClickAway(ref, () => {
    setIsVisible(false);
  });

  useEffect(() => {
    const checkCurrency = async (): Promise<void> => {
      const queryCurrency = router.query?.currency as string | null;

      if (queryCurrency && queryCurrency !== currentCurrency) {
        const newCurrency = CURRENCIES.find(
          (c) => c.key === queryCurrency.toUpperCase()
        );

        if (newCurrency) {
          await changeCurrency(
            { currency: newCurrency.key },
            { locale: newCurrency.defaultLocale, path: router.asPath }
          );

          if (router.locale !== newCurrency.defaultLocale) {
            router.replace(
              `/${newCurrency.defaultLocale}${router.asPath.split('?')[0]}`
            );
          }
        }
      }
    };

    checkCurrency();
  }, [router.query?.currency]); // eslint-disable-line

  useEffect(() => {
    if (currency && currency !== currentCurrency) {
      setCurrentCurrency(currency);
    }
  }, [currency]); // eslint-disable-line

  useEffect(() => {
    if (currencyRate && currentCurrencyRate !== currencyRate) {
      setCurrentCurrencyRate(currencyRate);
    }
  }, [currencyRate]); // eslint-disable-line

  return (
    <StyledCurrencyPicker ref={ref} className="currency-picker">
      <Button
        onClick={() => {
          setIsVisible(true);
        }}
        size="small"
        icon={RiCoinsLine}
      >
        {currentCurrency}
      </Button>
      <Modal visible={isVisible} close={() => setIsVisible(false)}>
        <div className="buttons">
          {shop.currencies.map((curr) => (
            <Button
              key={curr.key}
              className={currentCurrency === curr.key ? 'selected' : 'regular'}
              onClick={async () => {
                changeCurrency(
                  { currency: curr.key },
                  { locale: router.locale, path: router.asPath }
                );

                setCurrentCurrency(curr.key);
                setPopupState('none');
                setIsVisible(false);
              }}
            >
              {curr.name}
            </Button>
          ))}
        </div>
      </Modal>
    </StyledCurrencyPicker>
  );
};

const StyledCurrencyPicker = styled.div`
  > button {
    --button-background: var(--header-buttons-background);
    --button-foreground: var(--header-buttons-foreground);
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    button {
      margin: 0.5rem 0;

      &.regular {
        --button-background: #c9c9c9;
        --button-foreground: #333333;
      }
    }
  }
`;

export default CurrencyPicker;
