import { Dictionary, Shop, SimplePage, Theme } from 'data/types';
import { createContext } from 'react';

export type PopupStates =
  | 'sidebar'
  | 'checkout'
  | 'currency'
  | 'language'
  | 'alert'
  | 'none';

export type TrackingStates = 'none' | 'ready' | 'no-tracking';
export interface ContextProps {
  shop?: Shop;
  dictionary?: Dictionary; // eslint-disable-line
  // dictionary: Record<string, string>;
  theme?: Theme;
  device?: string;
  popupState: PopupStates;
  setPopupState?: React.Dispatch<React.SetStateAction<string>>;
  pages?: SimplePage[];
  trackingState: TrackingStates;
  setTrackingState?: React.Dispatch<React.SetStateAction<string>>;
  currentCurrency: string | null;
  setCurrentCurrency?: React.Dispatch<React.SetStateAction<string>>;
  currentCurrencyRate: number;
  setCurrentCurrencyRate?: React.Dispatch<React.SetStateAction<number>>;
}

export const GlobalContext = createContext<ContextProps>({
  popupState: 'none',
  trackingState: 'none',
  currentCurrency: null,
  currentCurrencyRate: 1,
});
