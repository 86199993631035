import cache from 'memory-cache';
import { getToken } from 'utils/helpers';
import { fetchAPI } from './fetch-api-data';
import { BuySectionProduct, FullProduct, ListProduct } from './types';

export const listProductItemQuery = `
{
  _id
  name
  slug
  images
  subText
  banner
  url
  inStock
  lockedPrices
  price
  priceFormatted
  beforePrice
  beforePriceFormatted
  artist { 
    name
  }
  variants {
    _id
    type 
    name
    selected 
    attributes {
      _id
      sku
      selected
      name
      color
      inStock
    }
  }
}
`;

export const fullProductQuery = `
  _id
  metaTitle 
  metaDescription
  metaKeywords
  metaImage
  canonicalUrl
  url
  slug
  sku
  image 
  images
  name
  subText
  lockedPrices
  price
  priceFormatted
  beforePriceFormatted
  inStock
  millisecondsLeftToPreorder
  confirmMessage
  shippingStartDate
  preorder
  variants {
    _id
    type 
    name
    selected 
    attributes {
      _id
      sku
      selected
      name
      color
      inStock
    }
  }

  artist { 
    name
    image
    logo
    description
    socials {
      facebook
      soundcloud
      instagram
      twitter
      itunes
      spotify
      youtube
      rss
      twitch
      tier
    }
  }
  description
`;

export const getProduct = async (
  slug: string,
  path: string,
  locale: string,
  variantSku?: string
): Promise<FullProduct> => {
  const query = `
  query($slug: String!, $variantSku: String) {
    product(slug: $slug, variantSku: $variantSku) {
      ${fullProductQuery}
      breadcrumb {
        _id
        type
        name
        href
      }
      relatedProducts ${listProductItemQuery}
    }
  }
  `;
  const productData = await fetchAPI(query, {
    variables: { slug, variantSku },
    headers: { path, locale },
  });
  const { product } = productData || {};
  return product;
};

export const getPreviewProduct = async (
  productId: string,
  path: string,
  locale: string,
  variantSku?: string
): Promise<FullProduct> => {
  const query = `
  query($productId: String!, $variantSku: String) {
    previewProduct(_id: $productId, variantSku: $variantSku) {
      ${fullProductQuery}
      organizationId
      breadcrumb {
        _id
        type
        name
        href
      }
      relatedProducts ${listProductItemQuery}
    }
  }
  `;
  const productData = await fetchAPI(query, {
    variables: { productId, variantSku },
    headers: { path, locale },
  });
  const { previewProduct } = productData || {};
  return previewProduct;
};

export const getSearchData = async (
  searchQuery: string,
  locale: string
): Promise<ListProduct[]> => {
  const query = `
  query($query: String!) {
    search(query: $query) ${listProductItemQuery}
  }
  `;
  const productData = await fetchAPI(query, {
    variables: { query: searchQuery },
    headers: { path: '/', token: getToken(), locale },
  });
  const { search } = productData || {};
  return search;
};

export const getProductBuyData = async (
  slug: string,
  path: string,
  locale: string,
  selectedVariants?: Record<string, string>
): Promise<BuySectionProduct> => {
  const query = `
  query($slug: String!, $selected: JSON) {
    productInfo(slug: $slug, selected: $selected) {
      _id
      banner
      name
      url
      price
      priceFormatted
      beforePriceFormatted
      inStock
      millisecondsLeftToPreorder
      confirmMessage
      shippingStartDate
      preorder
      variants {
        _id
        type 
        name
        selected 
        attributes {
          _id
          sku
          selected
          name
          color
          inStock
        }
      }
    }
  }
  `;

  console.log('info', {
    slug,
    path,
    locale,
    selectedVariants,
  });

  const productData = await fetchAPI(query, {
    variables: { slug, selected: selectedVariants },
    headers: { path, token: getToken(), locale },
  });
  const { productInfo } = productData;
  return productInfo;
};

// Til google feed
export const getAllProducts = async (
  locale: string
): Promise<FullProduct[]> => {
  const cacheKey = `getAllProducts-${locale}`;
  if (cache.get(cacheKey)) {
    return cache.get(cacheKey);
  }

  const query = `
  query {
    products {
      _id
      name
      metaDescription
      slug
      url
      inStock
      price
      priceFormatted
      preorder
      image
      variants {
        _id
        type 
        attributes {
          _id
          sku
          name
        }
      }
    }
  }
  `;
  const productsData = await fetchAPI(query, {
    variables: {},
    headers: { path: '/', locale },
  });
  const { products } = productsData;
  cache.put(cacheKey, products, 5 * 60 * 1000);

  return products;
};
