/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useRef } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { LANGUAGES } from 'utils/constants';
import { useClickAway } from 'react-use';
import Button from 'components/formitems/button';
import { FiGlobe } from 'react-icons/fi';
import Modal from './modal';

const LanguagePicker: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const { locale, asPath } = useRouter();
  useClickAway(ref, () => {
    setIsVisible(false);
  });
  // TODO: Link til direkte underside
  return (
    <StyledLanguagePicker ref={ref} className="language-picker">
      <Button
        onClick={() => {
          setIsVisible(true);
        }}
        size="small"
        icon={FiGlobe}
      >
        {locale.toUpperCase()}
      </Button>
      <Modal visible={isVisible} close={() => setIsVisible(false)}>
        <div className="buttons">
          {LANGUAGES.map((lang) => (
            <Button
              linkProps={{
                prefetch: false,
                href: asPath,
                locale: lang.key,
              }}
              key={lang.key}
              className={locale === lang.key ? 'selected' : 'regular'}
              onClick={() => setIsVisible(false)}
            >
              {lang.label}
            </Button>
          ))}
        </div>
      </Modal>
    </StyledLanguagePicker>
  );
};
const StyledLanguagePicker = styled.div`
  > button {
    --button-background: var(--header-buttons-background);
    --button-foreground: var(--header-buttons-foreground);
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    a {
      margin: 0.5rem 0;

      &.regular {
        --button-background: #c9c9c9;
        --button-foreground: #333333;
      }
    }
  }
`;
export default LanguagePicker;
